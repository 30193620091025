<template>
  <div class="page2">
    <HomeHeader :isShowLeft="true" :name="'园区碳排分析看板'" />
    <div class="main">
      <div class="item">
        <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/let-icon1.png"
          alt="">
        <div class="left">
          <p class="name">当日碳排放量（t/CO₂）</p>
          <span class="value">{{ discharge | numberFilter }}</span>
        </div>
        <div class="line"></div>
        <div class="right">
          <div>
            当月碳排放量（t/CO₂）
            <span class="discharge">{{ 9245821 + discharge | numberFilter }}</span>
          </div>
          <div>
            当年碳排放量（t/CO₂）
            <span class="discharge">{{ 36341722 + discharge | numberFilter }}</span>
          </div>
        </div>
      </div>
      <div class="item">
        <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/electricity.png"
          alt="">
        <div class="left">
          <p class="name">当日用电量（kwh）</p>
          <span class="value">{{ discharge | numberFilter }}</span>
        </div>
        <div class="line"></div>
        <div class="right">
          <div>
            当月用电量（kwh）
            <span class="discharge">{{ 9411234 + discharge | numberFilter }}</span>
          </div>
          <div>
            当年用电量（kwh）
            <span class="discharge">{{ 27411234 + discharge | numberFilter }}</span>
          </div>
        </div>
      </div>
      <div class="item gas">
        <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/gas.png" alt="">
        <div class="left">
          <p class="name">当日天然气量（m³）</p>
          <span class="value">{{ discharge | numberFilter }}</span>
        </div>
        <div class="line"></div>
        <div class="right">
          <div>
            当月天然气量（m³）
            <span class="discharge">{{ 6267371 + discharge | numberFilter }}</span>
          </div>
          <div>
            当年天然气量（m³）
            <span class="discharge">{{ 14521156 + discharge | numberFilter }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-box" @click="close">
      <div v-if="active !== 0" class="popup-line" :class="`popup-line${active}`"></div>
      <DataPopup v-if="active !== 0" :name="name" :top="top" :left="left" />

      <div class="garden" @click="changePopup(1)" :style="{ opacity: active === 1 ? '1' : '' }">
        <p class="liangjiang-text">重庆市两江数字经济产业园</p>
        <img class="cursor"
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/cursor.png" alt="">
        <img
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/analysisPark/1.png"
          alt="">
      </div>
      <div class="garden garden2" @click="changePopup(2)" :style="{ opacity: active === 2 ? '1' : '' }">
        <img class="cursor"
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/cursor.png" alt="">
        <img
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/analysisPark/2.png"
          alt="">
      </div>
      <div class="garden garden3" @click="changePopup(3)" :style="{ opacity: active === 3 ? '1' : '' }">
        <img class="cursor"
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/cursor.png" alt="">
        <img
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/analysisPark/3.png"
          alt="">
      </div>
      <div class="garden garden4" @click="changePopup(4)" :style="{ opacity: active === 4 ? '1' : '' }">
        <img class="cursor"
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/cursor.png" alt="">
        <img
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/analysisPark/4.png"
          alt="">
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeader from '@/components/HomeHeader'
import DataPopup from '@/components/DataPopup'
import { numAnimation, numberFilter } from '@/utils'
export default {
  components: { HomeHeader, DataPopup },
  data () {
    return {
      discharge: 354562,
      active: 0,
      top: '28.3%',
      left: '59.7%',
      name: '两江新区互联网产业园'
    }
  },
  filters: {
    numberFilter,
  },
  mounted () {
    this.updateEquipment()
  },
  methods: {
    close () {
      this.active = 0
    },
    changePopup (index) {
      console.log(index, 'active')
      if (index === 1) {
        this.$router.push({ path: '/LiangjiangNewArea' })
        return
      }
      setTimeout(() => {
        this.active = index
        if (index === 1) {
          this.name = '两江新区互联网产业园'
          this.top = '28.3%'
          this.left = '59.7%'
        } else if (index === 2) {
          this.name = '福安药业集团'
          this.top = '42.3%'
          this.left = '40.7%'
        } else if (index === 3) {
          this.name = '川仪分析工业园'
          this.top = '48.3%'
          this.left = '57.2%'
        } else if (index === 4) {
          this.name = '电力科学研究院建筑'
          this.top = '28%'
          this.left = '37%'
        }
      }, 0)

    },
    // 设置设备数量动画
    updateEquipment () {
      numAnimation(this.discharge, this.getNextRandomNumber(this.discharge, this.discharge + 100) || 0, this, 'discharge')
      setTimeout(this.updateEquipment, 3000)
    },
    // 获取随机数
    getNextRandomNumber (min, max) {
      var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
      // 判断下次随机数是否比当前随机数大，如果不大则递归调用函数获取新的随机数
      if (randomNumber <= this.discharge) {
        return this.getNextRandomNumber(min, max)
      }
      return randomNumber
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

.garden {
  transform: scale(0.64);
  position: absolute;
  left: 677px;
  top: 296px;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  .cursor {
    position: absolute;
    left: 50%;
    top: 1%;
    margin-left: -25px;
    margin-top: -25px;
  }

  .liangjiang-text {
    position: absolute;
    left: 145px;
    top: -53px;
    font-size: 30px;
    font-weight: bold;
    color: #00FBE7;
  }
}

.garden2 {
  left: 1195px;
  top: 587px;
}

.garden3 {
  left: 661px;
  top: 710px;
}

.garden4 {
  left: 236px;
  top: 487px;
}



.popup-line {
  position: absolute;
  top: 31.5%;
  left: 52.5%;
  width: 140px;
  height: 1px;
  background: #00FBE7;
  transform: rotate(-15deg);
}

.popup-line2 {
  width: 242px;
  top: 51%;
  left: 61%;
  transform: rotate(38deg);
}

.popup-line3 {
  width: 309px;
  top: 61%;
  left: 45%;
  transform: rotate(-53deg);
}

.popup-line4 {
  width: 345px;
  top: 39.2%;
  left: 21%;
  transform: rotate(-38deg);
}

.page2 {
  width: 100%;
  height: 100%;
  background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/5a6b21a8-80b9-4c26-bf77-7468c4f21b62img2.png');
  background-size: 100% 100%;
  cursor: pointer;
  position: relative;

  .popup-box {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
  }

  .main {
    display: flex;
    justify-content: space-between;
    margin-top: -30px;
    padding: 0 24px;

    >div {
      width: 608px;
      height: 96px;
      background: rgba(7, 116, 128, 0.7);
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #D6FFFF;

      img {
        width: 70px;
        height: 66px;
        margin-left: 14px;
      }

      .value {
        font-size: 24px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #00FBE7;
      }

      .left {
        margin-left: 5px;
      }

      .line {
        width: 1px;
        height: 40px;
        background: linear-gradient(180deg, rgba(237, 255, 255, 0) 0%, #E1FFFF 50%, rgba(214, 255, 255, 0) 100%);
        opacity: 0.8;
        margin-left: 12px;
      }

      .right {
        margin-left: 40px;

        .discharge {
          font-size: 14px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #00FBE7;
        }

        >div:nth-child(1) {
          margin-bottom: 12px;
        }
      }
    }

    .gas {
      .value {
        color: #F3E141;
      }

      .right {

        .discharge {
          color: #F3E141;
        }
      }
    }
  }
}</style>