<template>
  <div class="page_box">
    <header>
      <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/logo.png" alt="">
      <div class="header_right">
        <div class="header_right_time">
          {{ currentDateTime.formattedDateTime }}
        </div>
        <div class="header_right_week">{{ currentDateTime.formattedDayOfWeek }}</div>
      </div>
    </header>
    <div class="content">
      <!-- 左边部分 -->
      <div class="left">
        <div class="left_item">
          <img class="img_top_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_top_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <h3>&nbsp;&nbsp;数字身份标识</h3>
          <div class="img">
            <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/1.gif" alt="">
          </div>
          <p style="padding-top: 10px;" class="text">
            亿碳通基于星火链网给予碳账户唯一的数 字身份BID。 记录碳账户主体碳排量，界定各社会主体的低碳贡献、减碳责任和碳排放权边界，推进碳达峰碳中和工作落地、推进低碳社会建设。
          </p>
        </div>
        <div class="left_item">
          <img class="img_top_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_top_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_left2"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_right2"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <h3>&nbsp;&nbsp;碳资产存储与保护</h3>
          <div class="img">
            <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/2.gif" alt="">
          </div>
          <p style="padding-top: 10px;" class="text">
            亿碳通与国家级区块链星火链网深度融合，将企业的碳排放权碳配额、碳汇、碳固资产，上链存储，为企业提供核心数据的安全保障。
          </p>
        </div>

      </div>

      <!-- 中间部分 -->
      <div class="middle">
        <div class="middle_img">
          <img style="width: 800px;"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/max-center_gif.gif"
            alt="" srcset="">
          <!-- <img src="@/assets/中心动画_03.gif" alt="" srcset=""> -->
        </div>
        <div class="middle_text">
          <img class="img_top_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_top_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          亿碳通是基于亿利集团在绿色生态领域30余年的行业积累、国家级区块链星火链网，打造的双碳生态链综合服务平台。平台专注于“碳达峰、碳中和”数字化服务领域，旨在帮助企业及政府部门摸清“碳家底”，量化碳资产，规范碳生态，促进碳交易，对国家双碳目标战略具有重要推动作用。
        </div>
      </div>

      <!-- 右边部分 -->
      <div class="right">
        <div class="right_item">
          <img class="img_top_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_top_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <h3>&nbsp;&nbsp;碳交易</h3>
          <div class="img">
            <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/3.gif" alt="">
          </div>
          <p class="text">
            采用星火链网的智能合约技术，亿碳通极大的提高企业之间碳交易的效率保证碳交易的安全。
          </p>
        </div>
        <div class="right_item">
          <img class="img_top_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_top_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <h3>&nbsp;&nbsp;验真、朔源</h3>
          <div class="img">
            <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/4.gif" alt="">
          </div>
          <p class="text">
            平台利用区块链技术，对碳排及产品碳足迹的核算、核证过程和结果进行朔源，保证数据的真实性和有效性
          </p>
        </div>
        <div class="right_item">
          <img class="img_top_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_top_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_left"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <img class="img_bottom_right"
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
            alt="">
          <h3>&nbsp;&nbsp;存证</h3>
          <div class="img">
            <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/5.gif" alt="">
          </div>
          <p class="text">
            碳排核证结果、产品碳足迹的认证结果，利用星火链网的存证服务，有效的保证企业的双谈认证权益。
          </p>
        </div>
      </div>
    </div>
    <!-- <div id="indexLizi"></div> -->
  </div>
</template>

<script>
// import * as THREE from "three";

export default {
  name: "Pointwave",
  props: {
    amountX: {
      type: Number,
      default: 50,
    },
    amountY: {
      type: Number,
      default: 50,
    },
    color: {
      type: Number,
      // default: 0xffffff,
      // default: 0x097bdb,
      default: 0x085a5b,
    },
    top: {
      type: Number,
      default: 150,
    },
  },
  data () {
    return {
      count: 0,
      // 用来跟踪鼠标水平位置
      mouseX: 0,
      windowHalfX: null,
      // 相机
      camera: null,
      // 场景
      scene: null,
      // 批量管理粒子
      particles: null,
      // 渲染器
      renderer: null,
      // 时间展示
      currentDateTime: {},
    }
  },
  mounted () {
    // this.init();
    // this.animate();
    // this.onWindowResize();
    this.updateCurrentDateTime()
  },
  methods: {
    updateCurrentDateTime () {
      this.currentDateTime = JSON.parse(JSON.stringify(this.$utils.getCurrentDateTime()))
      setTimeout(this.updateCurrentDateTime, 1000)
    },
    init: function () {
      const SEPARATION = 100
      const SCREEN_WIDTH = window.innerWidth
      const SCREEN_HEIGHT = window.innerHeight
      const container = document.createElement("div")
      this.windowHalfX = window.innerWidth / 2
      container.style.position = "relative"
      container.style.top = `${this.top}px`
      container.style.height = `${SCREEN_HEIGHT}px`
      document.getElementById("indexLizi").appendChild(container)

      this.camera = new THREE.PerspectiveCamera(
        75,
        SCREEN_WIDTH / SCREEN_HEIGHT,
        0.1,
        10000
      )
      this.camera.position.z = 1000

      this.scene = new THREE.Scene()

      const numParticles = this.amountX * this.amountY
      const positions = new Float32Array(numParticles * 3)
      const scales = new Float32Array(numParticles)
      // 初始化粒子位置和大小
      let i = 0
      let j = 0
      for (let ix = 0; ix < this.amountX; ix++) {
        for (let iy = 0; iy < this.amountY; iy++) {
          positions[i] = ix * SEPARATION - (this.amountX * SEPARATION) / 2
          positions[i + 1] = 0
          positions[i + 2] = iy * SEPARATION - (this.amountY * SEPARATION) / 2
          scales[j] = 1
          i += 3
          j++
        }
      }

      const geometry = new THREE.BufferGeometry()
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      )
      geometry.setAttribute("scale", new THREE.BufferAttribute(scales, 1))
      // 初始化粒子材质
      const material = new THREE.ShaderMaterial({
        uniforms: {
          color: { value: new THREE.Color(this.color) },
        },
        vertexShader: `
          attribute float scale;
          void main() {
            vec4 mvPosition = modelViewMatrix * vec4( position, 2.0 );
            gl_PointSize = scale * ( 300.0 / - mvPosition.z );
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
        fragmentShader: `
          uniform vec3 color;
          void main() {
            if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
            gl_FragColor = vec4( color, 0.5 );
          }
        `,
      })

      this.particles = new THREE.Points(geometry, material)
      this.scene.add(this.particles)

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.renderer.setSize(container.clientWidth, container.clientHeight)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setClearAlpha(0)
      container.appendChild(this.renderer.domElement)

      window.addEventListener("resize", this.onWindowResize, {
        passive: false,
      })
      document.addEventListener("mousemove", this.onDocumentMouseMove, {
        passive: false,
      })
      document.addEventListener("touchstart", this.onDocumentTouchStart, {
        passive: false,
      })
      document.addEventListener("touchmove", this.onDocumentTouchMove, {
        passive: false,
      })
    },
    render: function () {
      this.camera.position.x += (this.mouseX - this.camera.position.x) * 0.05
      this.camera.position.y = 400
      this.camera.lookAt(this.scene.position)
      const positions = this.particles.geometry.attributes.position.array
      const scales = this.particles.geometry.attributes.scale.array
      // 计算粒子位置及大小
      let i = 0
      let j = 0
      for (let ix = 0; ix < this.amountX; ix++) {
        for (let iy = 0; iy < this.amountY; iy++) {
          positions[i + 1] =
            Math.sin((ix + this.count) * 0.3) * 100 +
            Math.sin((iy + this.count) * 0.5) * 100
          scales[j] =
            (Math.sin((ix + this.count) * 0.3) + 1) * 8 +
            (Math.sin((iy + this.count) * 0.5) + 1) * 8
          i += 3
          j++
        }
      }
      // 重新渲染粒子
      this.particles.geometry.attributes.position.needsUpdate = true
      this.particles.geometry.attributes.scale.needsUpdate = true
      this.renderer.render(this.scene, this.camera)
      this.count += 0.1
    },
    animate: function () {
      requestAnimationFrame(this.animate)
      this.render()
    },
    onDocumentMouseMove: function (event) {
      this.mouseX = event.clientX - this.windowHalfX
    },
    onDocumentTouchStart: function (event) {
      if (event.touches.length === 1) {
        this.mouseX = event.touches[0].pageX - this.windowHalfX
      }
    },
    onDocumentTouchMove: function (event) {
      if (event.touches.length === 1) {
        event.preventDefault()
        this.mouseX = event.touches[0].pageX - this.windowHalfX
      }
    },
    onWindowResize: function () {
      this.windowHalfX = window.innerWidth / 2
      this.camera.aspect = window.innerWidth / window.innerHeight
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(window.innerWidth, window.innerHeight)
    },
  },
}
</script>

<style scoped lang="scss">
// #indexLizi {
//   width: 100%;
//   height: 1000px;
// }
p {
  margin: 0;
}

.page_box {
  background-image: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/background-big.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  // position: relative;

  img {
    border-radius: 4px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1920px;
    height: 80px;
    border-bottom: 1px solid rgba($color: #D6FFFF, $alpha: 0.3);
    background: linear-gradient(180deg, rgba(20, 242, 227, 0.3) 0%, rgba(2, 72, 72, 0.3) 100%);

    >img {
      width: 161px;
      height: auto;
      margin-left: 24px;
    }

    .header_right {
      color: #09E8F8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 24px;

      &_week {
        text-align: right;
      }

      .header_right_time {
        font-family: DIN-Medium, DIN;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    // height: 970px;
    height: 100%;

    .text {
      font-size: 16px;
    }

    .left {
      width: 510px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 25px;
      height: 960px;

      &_item {
        // height: 440px;
        background-color: rgba(3, 82, 86, 0.2);
        color: #D6FFFF;
        padding: 14px;
        border-radius: 6px;
        position: relative;
        background: linear-gradient(to top, rgba(0, 255, 255, 0.68) 0%, rgba(5, 168, 172, 0.3) 100%);

        .img_top_left {
          position: absolute;
          top: -4px;
          left: -4px;
        }

        .img_top_right {
          position: absolute;
          top: -4px;
          right: -4px;
          transform: scaleX(-1);
        }

        .img_bottom_left {
          position: absolute;
          bottom: -4px;
          left: -4px;
          transform: rotate(-135deg);
        }

        .img_bottom_left2 {
          position: absolute;
          bottom: -4px;
          left: -4px;
          transform: rotate(-135deg);
        }

        .img_bottom_right {
          position: absolute;
          bottom: -5px;
          right: -5px;
          transform: rotate(-180deg);
        }

        .img_bottom_right2 {
          position: absolute;
          bottom: -5px;
          right: -5px;
          transform: rotate(-180deg);
        }

        h3 {
          font-size: 16px;
          font-weight: 500;
          position: relative;
          color: #fff;
        }

        h3:before {
          content: "";
          position: absolute;
          top: 5px;
          left: 0;
          width: 2px;
          height: 14px;
          background: linear-gradient(180deg, rgba(88, 250, 244, 0.8) 0%, #00FBE7 100%);
        }

        .img {
          width: 468px;
          height: 330px;
          // background-color: #D6FFFF;
          margin: 17px 0 12px 0;

          img {
            width: 482px;
          }
        }
      }

      &_item:first-child {
        margin-bottom: 16px;
      }
    }

    .middle {
      width: 824px;
      height: 960px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_img {
        margin-top: 16px;
        text-align: center;
      }

      &_text {
        margin-top: 24px;
        padding: 13px 16px;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        color: #D6FFFF;
        border: 1px solid rgba(3, 82, 86, 0.2);
        border-radius: 6px;
        position: relative;
        background: linear-gradient(to top, rgba(0, 255, 255, 0.45) 0%, rgba(5, 168, 172, 0.3) 100%);

        .img_top_left {
          position: absolute;
          top: -5px;
          left: -6px;
        }

        .img_top_right {
          position: absolute;
          top: -5px;
          right: -6px;
          transform: scaleX(-1);
        }

        .img_bottom_left {
          position: absolute;
          bottom: -4px;
          left: -4px;
          transform: rotate(-135deg);
        }

        .img_bottom_right {
          position: absolute;
          bottom: -5px;
          right: -6px;
          transform: rotate(-180deg);
        }
      }
    }

    .right {
      width: 510px;
      height: 960px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 25px;

      &_item {
        height: 308px;
        // background-color: rgba(3, 82, 86, 0.2);
        background: linear-gradient(to top, rgba(0, 255, 255, 0.68) 0%, rgba(5, 168, 172, 0.3) 100%);

        padding: 14px;
        border-radius: 6px;
        position: relative;

        .img_top_left {
          position: absolute;
          top: -5px;
          left: -5px;
        }

        .img_top_right {
          position: absolute;
          top: -5px;
          right: -5px;
          transform: scaleX(-1);
        }

        .img_bottom_left {
          position: absolute;
          bottom: -4px;
          left: -4px;
          transform: rotate(-135deg);
        }

        .img_bottom_right {
          position: absolute;
          bottom: -5px;
          right: -5px;
          transform: rotate(-180deg);
        }

        h3 {
          font-size: 16px;
          font-weight: 500;
          position: relative;
          color: #fff;
        }

        h3:before {
          content: "";
          position: absolute;
          top: 5px;
          left: 0;
          width: 2px;
          height: 14px;
          background: linear-gradient(180deg, rgba(88, 250, 244, 0.8) 0%, #00FBE7 100%);
        }

        .img {
          width: 468px;
          // height: 60px;
          // background-color: #D6FFFF;
          margin: 16px 0 8px 0;

          img {
            width: 482px;
          }
        }
      }

      &_item:not(:last-child) {
        margin-bottom: 10px;
      }


    }
  }

  /**
  * 背景动态波浪效果
  */
  #indexLizi {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px;
  }
}</style>