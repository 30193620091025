<template>
  <div class="recommendPage">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide>
        <Page1 />
      </swiper-slide>
      <swiper-slide>
        <Page3 />
      </swiper-slide>
      <swiper-slide>
        <Page2 />
      </swiper-slide>
      <swiper-slide>
        <Page4 ref="page4" />
      </swiper-slide>
    </swiper>

    <!-- 切换按钮 -->
    <div class="side-switching" :class="[isUnfold ? '' : 'unfold']">
      <div>
        <div class="btn" @click="() => isUnfold = !isUnfold">
          <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/pack-up.svg"
            alt="">
        </div>
        <div class="side-main" v-show="isUnfold">
          <div @click="switchSlide(1)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn1.png"
              alt="">
            <p>星火链网 | 赋能双碳</p>
          </div>
          <div @click="switchSlide(2)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn2.png"
              alt="">
            <p>　碳数据 | 全景大屏</p>
          </div>
          <div @click="switchSlide(3)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn3.png"
              alt="">
            <p>园区碳排 | 分析看板</p>
          </div>
          <div @click="switchSlide(4)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn4.png"
              alt="">
            <p>　汽车制造 | 碳足迹核算</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper"
import "swiper/dist/css/swiper.css"

import Page1 from './page1.vue'
import Page2 from './page2.vue'
import Page3 from './page3.vue'
import Page4 from './page4.vue'

export default {
  components: {
    swiper,
    swiperSlide,
    Page1, Page2, Page3, Page4
  },
  data () {
    return {
      swiperOption: {
        loop: true,
        speed: 2000,
        easing: 'ease-in-out',
        autoplay: false, // 开发时设置停止轮播动画
        initialSlide: 0, // 当前展示的下标 开发用
        effect: 'fade', // 使用渐入渐出效果
        fadeEffect: {
          crossFade: true // 渐入渐出交叉淡化
        },
        // autoplay: {
        //   delay: 6000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
      },
      // 选项是否展开
      isUnfold: true,
      lastClickTime: 0,
      timer: null
    }
  },
  created () {
    if (this.$route.query.index) {
      console.log(this.swiperOption)
      this.swiperOption.initialSlide = Number(this.$route.query.index)
    }
  },
  mounted () {
    this.handleClick()
  },
  methods: {
    // 停止点击按钮一段时间就收起按钮
    handleClick () {
      this.lastClickTime = Date.now()
      this.isUnfold = true

      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.isUnfold = false
      }, 10000)
    },
    // 切换轮播
    switchSlide (index) {
      // 获取 Swiper 实例
      const swiper = this.$refs.mySwiper.swiper
      // 切换到指定的 slide
      swiper.slideTo(index)
      if (index == 4) {
        this.$refs.page4.startVideo()
      } else {
        this.$refs.page4?.pauseVideo()
      }

      // 点击时重置按钮时间
      this.handleClick()
    },
  }
}
</script>
<style scoped  lang="scss">
.recommendPage {
  width: 1920px;
  height: 1080px;
  // background: pink;
  position: relative;
}

.side-switching {
  position: absolute;
  left: 0;
  width: 100%;
  height: 79px;
  bottom: 0;
  z-index: 10;

  >div {
    display: flex;
    height: 100%;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 100%;
    background: linear-gradient(to bottom, rgba(#00E6FF, 0), rgba(#05DEE0, 0.16) 50%, rgba(#8AFFFF, 0.5) 75%, rgba(#00FEFF, 0.6) 100%);
    cursor: pointer;

    img {
      transform: rotate(180deg);
    }
  }

  .side-main {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba(18, 67, 57, 0.8) 0%, rgba(20, 73, 66, 0) 100%);

    >div {
      width: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 60px;

      img {
        width: 44px;
        height: 44px;
      }

      p {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #D6FFFF;
        line-height: 20px;
        letter-spacing: 1px;
        text-align: center;
        margin: 0;
      }
    }
  }
}

.unfold {
  transition: all 0.2s ease-in-out;
  width: 24px;

  .btn img {
    transition: all 0.2s ease-in-out;
    transform: rotate(0deg);
  }

  .side-main>div {
    transition: all 0.2s ease-in-out;
    width: 0;
  }
}

.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  img {
    width: 100%;
  }
}
</style>

