<template>
  <div class="data-popup" :style="{ top: top, left: left }">
    <div class="title">{{ name }}</div>
    <div class="main">
      <img class="img_top_left"
        src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
        alt="">
      <img class="img_top_right"
        src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
        alt="">
      <img class="img_bottom_left"
        src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
        alt="">
      <img class="img_bottom_right"
        src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
        alt="">
      <div>
        <div class="main-title">
          <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/equipment.png"
            alt="">
          在线设备数量： <span>{{ num || numberFilter }}</span> 个
        </div>

        <div class="dosage">
          <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/electricity.png"
            alt="">
          <div class="day">
            <p>当日{{ name !== '太阳能' && name !== '风车' ? '用' : '发' }}电量（kwh）</p>
            <span>{{ discharge + 900 || numberFilter }}</span>
          </div>
          <div class="month">
            <p>当月{{ name !== '太阳能' && name !== '风车' ? '用' : '发' }}电量（kwh）</p>
            <span>{{ (discharge + 900) * 30 || numberFilter }}</span>
          </div>
        </div>
        <div v-show="name !== '太阳能' && name !== '风车'" class="dosage">
          <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/gas.png" alt="">
          <div class="day">
            <p>当日天然气量（m³）</p>
            <span>{{ discharge + 557 || numberFilter }}</span>
          </div>
          <div class="month">
            <p>当月天然气量（m³）</p>
            <span>{{ (discharge + 557) * 30 || numberFilter }}</span>
          </div>
        </div>
        <div v-show="name !== '太阳能' && name !== '风车'" class="dosage">
          <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/let-icon1.png"
            alt="">
          <div class="day">
            <p>当日碳排放量（t/CO₂）</p>
            <span>{{ discharge + 458 || numberFilter }}</span>
          </div>
          <div class="month">
            <p>当月碳排放量（t/CO₂）</p>
            <span>{{ (discharge + 234) * 30 || numberFilter }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numAnimation, numberFilter } from '@/utils'
export default {
  props: {
    name: {
      type: String,
      default: '两江新区互联网产业园'
    },
    top: {
      type: String,
      default: '28.3%'
    },
    left: {
      type: String,
      default: '59.7%'
    },
    data: {
      type: Object,
      default: () => {
        return {
          num: 1680,
          data: [
            [2458, 73740],
            [3557, 106710],
            [1458, 43740],
          ]
        }
      }
    },
  },
  data () {
    return {
      discharge: 0,
    }
  },
  computed: {
    num () {
      let max = 15000
      let min = 5000
      if (this.name !== '风车' && this.name !== '太阳能') {
        max = 5000
        min = 1000
      }
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  },
  mounted () {
    this.updateEquipment()
  },
  methods: {
    // 设置设备数量动画
    updateEquipment () {
      numAnimation(this.discharge, this.getNextRandomNumber(this.discharge, this.discharge + 500) || 0, this, 'discharge')
      setTimeout(this.updateEquipment, 3000)
    },
    // 获取随机数
    getNextRandomNumber (min, max) {
      var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
      // 判断下次随机数是否比当前随机数大，如果不大则递归调用函数获取新的随机数
      if (randomNumber <= this.discharge) {
        return this.getNextRandomNumber(min, max)
      }
      return randomNumber
    }
  }
}
</script>

<style lang="scss" scoped>
.data-popup {
  width: 439px;
  position: absolute;
  z-index: 10;
  top: 40%;
  left: 40%;

  .title {
    height: 35px;
    background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/carbon-data-title-back.png');
    background-size: cover;
    margin-bottom: 10px;
    line-height: 28px;
    padding-left: 24px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #D6FFFF;
  }

  .main {
    width: 439px;
    height: 278px;
    border: 1px solid;
    background: rgba(0, 255, 255, 0.49);
    border: 1px solid rgba(3, 82, 86, 0.2);
    position: relative;
    border-radius: 6px;

    >div {
      background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/gridding.png');
      background-size: 100% 100%;
      height: 100%;

      .main-title {
        padding: 18px 0 24px 16px;

        img {
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }

        font-size: 16px;
        font-family: PingFangSC-Medium,
        PingFang SC;
        color: #D6FFFF;

        span {
          color: #12EFFF;
          font-weight: 600;
        }
      }

      .dosage {
        display: flex;

        img {
          width: 70px;
          height: 66px;
          margin-right: 6px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #D6FFFF;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          span {
            color: #12EFFF;
            font-size: 18px;
            font-weight: 600;
          }
        }

        .day {
          width: 150px;
        }

        .month {
          margin-left: 50px;
        }
      }
    }

    .img_top_left {
      position: absolute;
      top: -5px;
      left: -6px;
    }

    .img_top_right {
      position: absolute;
      top: -5px;
      right: -6px;
      transform: scaleX(-1);
    }

    .img_bottom_left {
      position: absolute;
      bottom: -5px;
      left: -6px;
      transform: rotate(-135deg);
    }

    .img_bottom_right {
      position: absolute;
      bottom: -5px;
      right: -6px;
      transform: rotate(-180deg);
    }
  }
}</style>