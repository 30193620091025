<template>
  <div class="page3">
    <HomeHeader />
    <div class="main">
      <div class="left">
        <!-- 碳排放统计 -->
        <div class="let-box">
          <div class="chunk-title">碳排放统计</div>
          <div class="chunk-content">
            <div class="let">
              <img class="angle angle1"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle2"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle3"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle4"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <div class="let-item">
                <div>
                  <div class="name">碳排放总量（万吨/CO₂）</div>
                  <div class="value">{{ discharge }}</div>
                </div>
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/let-icon1.png"
                  alt="">
              </div>
            </div>
            <div class="let">
              <img class="angle angle1"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle2"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle3"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle4"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <div class="let-item">
                <div>
                  <div class="name">能源消耗总量（万吨煤）</div>
                  <div class="value">{{ 1593 + discharge }}</div>
                </div>
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/let-icon2.png"
                  alt="">
              </div>
            </div>
            <div class="let">
              <img class="angle angle1"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle2"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle3"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle4"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <div class="let-item">
                <div>
                  <div class="name">碳排放强度（吨CO₂/万元）</div>
                  <div class="value">1.22</div>
                </div>
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/let-icon3.png"
                  alt="">
              </div>
            </div>
            <div class="let">
              <img class="angle angle1"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle2"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle3"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <img class="angle angle4"
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                alt="">
              <div class="let-item">
                <div>
                  <div class="name">能耗强度（吨煤/万元）</div>
                  <div class="value">4.25</div>
                </div>
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/let-icon4.png"
                  alt="">
              </div>
            </div>
            <img class="img_top_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_top_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
          </div>
        </div>
        <!-- 星火链网上数据 -->
        <div class="spark-box">
          <div class="chunk-title">星火链网上链数据</div>
          <div class="chunk-content">
            <img class="img_top_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_top_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <div class="spark-left">
              <div class="item">
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/spark-data-back.png"
                  alt="">
                <span class="value">{{ 413 + discharge }}个</span>
                <span class="name">BID</span>
              </div>
              <div class="item item3">
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/spark-data-back.png"
                  alt="">
                <span class="value">296万个</span>
                <span class="name">区块</span>
              </div>
            </div>
            <div class="spark-center">
              <div class="arrows-box">
                <div class="arrows"><span></span></div>
                <div class="arrows arrows2"><span></span></div>
              </div>
              <img
                src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/spark-logo.png"
                alt="">
              <div class="arrows-box">
                <div class="arrows arrows3"><span></span></div>
                <div class="arrows arrows4"><span></span></div>
              </div>
            </div>
            <div class="spark-right">
              <div class="item">
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/spark-data-back.png"
                  alt="">
                <span class="name">合约</span>
                <span class="value">{{ discharge - 2800 }}个</span>
              </div>
              <div class="item item3">
                <img
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/spark-data-back.png"
                  alt="">
                <span class="name">交易</span>
                <span class="value">{{ 806 + discharge }}笔</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 星火链网碳账户数据 -->
        <div class="carbon-account-box">
          <div class="chunk-title">星火链网碳账户数据</div>
          <div class="chunk-content">
            <img class="img_top_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_top_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <div class="carbon-account-left">
              <div class="title">
                <span class="triangle"></span>
                <span class="name">碳账户数</span>
              </div>
              <div class="carbonAccount">
                <div v-for="(item, index) in carbonAccount" :key="index">
                  <span>{{ item.name }}</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </div>
            </div>
            <div class="carbon-account-right">
              <div class="title">
                <span class="triangle"></span>
                <span class="name">碳账户等级</span>
              </div>
              <div class="accountLevel">
                <img class="angle angle1"
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                  alt="">
                <img class="angle angle2"
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                  alt="">
                <img class="angle angle3"
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                  alt="">
                <img class="angle angle4"
                  src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/angle.png"
                  alt="">
                <div v-for="(item, index) in accountLevel" :key="index">
                  <div> <span>优秀</span> {{ item.value1 }} </div>
                  <div> <span>良好</span> {{ item.value2 }} </div>
                  <div> <span>中等</span> {{ item.value3 }} </div>
                  <div> <span>较差</span> {{ item.value4 }} </div>
                </div>
                <div>-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/map.png" alt="">
      </div>
      <div class="right">
        <!-- 碳排放范围占比 -->
        <div class="let-box">
          <div class="chunk-title">碳排放范围占比</div>
          <div class="chunk-content">
            <img class="img_top_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_top_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <PieChart :roseType="'area'" />
          </div>
        </div>

        <!-- 碳排放能源占比 -->
        <div class="emission-energy-box">
          <div class="chunk-title">碳排放能源占比</div>
          <div class="chunk-content">
            <img class="img_top_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_top_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <PieChart :className="'PieChart2'" :chartsData="emissionEnergy" />
          </div>
        </div>

        <!-- 碳排放能源占比 -->
        <div class="emission-intensity-box">
          <div class="chunk-title">碳排放能源占比</div>
          <div class="chunk-content">
            <img class="img_top_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_top_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_left"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <img class="img_bottom_right"
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/obtuse-angle.png"
              alt="">
            <LineChart />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeader from '@/components/HomeHeader'
import PieChart from '@/components/Charts/PieChart'
import LineChart from '@/components/Charts/LineChart'
import { numAnimation, numberFilter } from '@/utils'
export default {
  components: { HomeHeader, PieChart, LineChart },
  data () {
    return {
      carbonAccount: [
        { name: '能源企业', value: '150' },
        { name: '工业企业', value: '169' },
        { name: '交通运输', value: '108' },
        { name: '城乡建设', value: '56' },
        { name: '新基建', value: '30' },
        { name: '农业农村', value: '26' },
        { name: '居民', value: '120310' },
      ],
      accountLevel: [
        { value1: '100', value2: '22', value3: '18', value4: '10' },
        { value1: '82', value2: '38', value3: '29', value4: '20' },
        { value1: '76', value2: '14', value3: '10', value4: '8' },
        { value1: '32', value2: '16', value3: '6', value4: '2' },
        { value1: '20', value2: '4', value3: '3', value4: '3' },
        { value1: '19', value2: '3', value3: '2', value4: '2' },
      ],
      emissionEnergy: {
        legend: [],
        data: [
          { value: 840, name: '工业' },
          { value: 658, name: '能源' },
          { value: 433, name: '交通运输' },
          { value: 424, name: '农业农村' },
          { value: 532, name: '新基建' },
          { value: 662, name: '城乡建设' },
        ]
      },
      // 
      discharge: 2888
    }
  },
  filters: {
    numberFilter,
  },
  mounted () {
    this.updateEquipment()
  },
  methods: {
    // 设置设备数量动画
    updateEquipment () {
      numAnimation(this.discharge, this.getNextRandomNumber(this.discharge, this.discharge + 20) || 0, this, 'discharge')
      setTimeout(this.updateEquipment, 3000)
    },
    // 获取随机数
    getNextRandomNumber (min, max) {
      var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
      // 判断下次随机数是否比当前随机数大，如果不大则递归调用函数获取新的随机数
      if (randomNumber <= this.discharge) {
        return this.getNextRandomNumber(min, max)
      }
      return randomNumber
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.page3 {
  width: 100%;
  height: 100%;
  background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/c8e65f87-1f39-4d0a-9f04-9a9238abbcfecarbon-data-back.png');
  background-size: 100% 100%;

  .main {
    display: flex;
    justify-content: space-between;
    padding: 0 23px;
    margin-top: -55px;

    >div {
      // flex: 1;

      .chunk-title {
        width: 500px;
        height: 45px;
        background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/carbon-data-title-back.png') no-repeat;
        background-size: 100% 100%;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #D6FFFF;
        margin-bottom: 5px;
      }

      .chunk-content {
        position: relative;
        width: 500px;
        min-height: 240px;
        border: 1px solid transparent;
        // border-image: linear-gradient(0deg, rgba(0, 255, 255, 0.96), rgba(221, 255, 255, 0)) 1 1;
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(96, 229, 229, 0.49) 0%, rgba(103, 164, 225, 0) 100%);

        .img_top_left {
          position: absolute;
          top: -5px;
          left: -6px;
        }

        .img_top_right {
          position: absolute;
          top: -5px;
          right: -6px;
          transform: scaleX(-1);
        }

        .img_bottom_left {
          position: absolute;
          bottom: -5px;
          left: -6px;
          transform: rotate(-135deg);
        }

        .img_bottom_right {
          position: absolute;
          bottom: -5px;
          right: -6px;
          transform: rotate(-180deg);
        }
      }
    }

    .left,
    .right {
      width: 500px;
    }

    .center {
      padding: 0 25px;
      padding-top: 80px;

      img {
        width: 100%;
      }
    }

    // 碳排放统计
    .let-box {
      .chunk-content {
        padding: 16px;
        box-sizing: border-box;
      }

      .let {
        display: inline-block;
        position: relative;

        &:nth-child(2n) {
          margin-left: 14px;
        }

        &:nth-child(1) {
          margin-bottom: 16px;

        }

        &:nth-child(2) {
          margin-bottom: 16px;

          .let-item .value {
            color: #09E8F8;
          }
        }

        &:nth-child(3) {

          .let-item .value {
            color: #7CFDAB;
          }
        }

        &:nth-child(4) {

          .let-item .value {
            color: #F3E141;
          }
        }

        // 角
        .angle {
          position: absolute;
          width: 5px;
          height: 5px;
        }

        .angle2 {
          right: 0;
          transform: rotate(90deg);
        }

        .angle3 {
          left: 0;
          bottom: 0;
          transform: rotate(270deg);
        }

        .angle4 {
          right: 0;
          bottom: 0;
          transform: rotate(180deg);
        }
      }

      .let-item {
        position: relative;
        padding: 26px 0 0 16px;
        display: flex;
        width: 226px;
        height: 96px;
        background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/gridding.png');


        .name {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #D6FFFF;
        }

        .value {
          font-size: 24px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #00FBE7;
        }

        img {
          width: 73px;
          height: 66px;
          position: absolute;
          top: 16px;
          right: 6px;
        }
      }
    }

    // 星火链网上链数据
    .spark-box {
      margin-top: 20px;

      .chunk-content {
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/gridding240.png');
        background-size: 100% 100%;

        .spark-left,
        .spark-right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .item {
            position: relative;

            img {
              width: 152px;
              height: 59px;
            }

            .value {
              display: inline-block;
              font-size: 18px;
              font-family: DIN-Bold, DIN;
              font-weight: bold;
              color: #00FBE7;
              position: absolute;
              left: 16px;
              top: 20px;
            }

            .name {
              display: inline-block;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #D6FFFF;
              position: absolute;
              left: 112px;
              top: 17px;
            }
          }

          .item3 {
            .value {
              color: #7CFDAB;
            }
          }
        }

        .spark-center {
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 120px;
            height: 96px;
          }

          .arrows-box {
            .arrows {
              width: 21px;
              height: 46px;
              border: 1px solid;
              border-left: transparent;
              border-image: linear-gradient(180deg, rgba(0, 255, 255, 0.96), rgba(221, 255, 255, 0)) 1 1;
              position: relative;
              top: -8px;
              left: 4px;

              span {
                display: inline-block;
                width: 4px;
                height: 5px;
                border-right: 5px solid #44F6FF;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                position: absolute;
                top: -4px;
                left: -5px;
              }
            }

            .arrows2 {
              top: 22px;
              transform: rotateX(180deg);
            }

            .arrows3 {
              left: -4px;
              transform: rotateY(-180deg);
            }

            .arrows4 {
              top: 22px;
              left: -4px;
              transform: rotate(180deg);
            }
          }
        }

        .spark-right {

          img {
            transform: rotateY(180deg);
          }

          .item {
            .name {
              left: 16px;
              top: 17px;
            }

            .value {
              left: 80px;
              top: 20px;
            }
          }
        }
      }


    }

    // 星火链网碳账户数据
    .carbon-account-box {
      margin-top: 20px;

      .chunk-content {
        padding: 18px 16px 6px 16px;
        display: flex;
        background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/gridding240.png');
        background-size: 100% 100%;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .triangle {
          display: inline-block;
          width: 8px;
          height: 12px;
          border-left: 12px solid #44F6FF;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          margin-right: 4px;
        }

        .name {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #D6FFFF;
        }
      }

      .carbon-account-left {
        .carbonAccount {
          >div {
            width: 170px;
            height: 30px;
            padding: 0 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: url("https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/carbon-account-number-back.png");
            background-size: 100% 100%;
            margin-bottom: 5px;

            span {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #D6FFFF;
            }

            .value {
              font-size: 14px;
              font-family: DIN-Bold, DIN;
              font-weight: bold;
              color: #00FBE7;
            }
          }
        }
      }

      .carbon-account-right {
        margin-left: 8px;

        .accountLevel {
          width: 290px;
          padding: 0 16px;
          background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/level-gridding.png');
          background-size: 100% 100%;
          position: relative;

          // 角
          .angle {
            position: absolute;
            width: 5px;
            height: 5px;
          }

          .angle1 {
            left: 0;
          }

          .angle2 {
            right: 0;
            transform: rotate(90deg);
          }

          .angle3 {
            left: 0;
            bottom: 0;
            transform: rotate(270deg);
          }

          .angle4 {
            right: 0;
            bottom: 0;
            transform: rotate(180deg);
          }

          >div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            margin-bottom: 5px;

            div {
              width: 50px;
              font-size: 12px;
              font-family: DIN-Bold, DIN;
              font-weight: 500;
              color: #fff;

              span {
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #7CFDAB;
              }
            }

            >div:nth-child(2) span {
              color: #00FBE7;
            }

            >div:nth-child(3) span {
              color: #09E8F8;
            }

            >div:nth-child(4) span {
              color: #F3E141;
            }
          }
        }
      }
    }

    // 碳排放能源占比
    .emission-energy-box {
      margin-top: 20px;
    }

    .emission-intensity-box {
      margin-top: 20px;
    }
  }
}
</style>