<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>
  
<script>
import echarts from 'echarts'
import resize from './resize'

const animationDuration = 1000

export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: 'lineChart'
    },
    // 图表宽
    width: {
      type: String,
      default: '100%'
    },
    // 图表高
    height: {
      type: String,
      default: '270px'
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return {
          label: ['2018', '2019', '2020', '2021', '2022'],
          legend: ['工业', '能源', '新基建', '城乡建设', '交通运输', '农业农村'],
          data: [
            [17, 22, 26, 20, 35],
            [12, 16, 21, 25, 19],
            [23, 28, 32, 26, 21],
            [14, 19, 23, 18, 13],
            [13, 19, 17, 14, 18],
            [8, 7, 6, 9, 5],
          ]
        }
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {
        return ['#F3E141', '#00FBE7', '#09E8F8', '#D0EC21', '#2E9EFF', '#7CFDAB']
      }
    },
    // 数值单位
    unit: {
      type: String,
      default: '万'
    },
    // 数值单位
    max: {
      type: Number,
      default: 50
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    // 生成Series
    createSeries () {
      const arr = []
      this.chartsData.data.forEach((item, index) => {
        const name = this.chartsData.legend[index]
        arr.push({
          name: name,
          type: 'line',
          data: item,
          animationDuration,
          lineStyle: {
            width: 1.5
          }
        })
      })
      return arr
    },
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      const _this = this

      // 定义自动轮播的数据项和索引
      const data = this.chartsData.label
      let currentIndex = 0
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            var result = ''
            params.forEach(function (item) {
              result += `${item.marker} ${params.length === 1 ? item.name : item.seriesName}: ${item.value}${_this.unit}<br>`
            })
            return result
          }
        },
        legend: {
          bottom: '16',
          icon: 'roundRect',
          textStyle: {
            color: '#fff', // 图例文本颜色
            fontSize: 12 // 图例文本字体大小
          },
          data: _this.chartsData.legend
        },
        color: _this.colors,
        grid: {
          top: 30,
          left: '2%',
          right: '2%',
          bottom: _this.chartsData.legend.length ? '44' : '8',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: _this.chartsData.label,
          axisbar: {
            barStyle: {
              opacity: 0
            }
          },
          axisLine: false,
          axisLabel: {
            lineHeight: 25,
            textStyle: {
              color: '#fff' // 坐标值得具体的颜色
            },
            interval: 0,
            rotate: 0
          },
        }],
        yAxis: [{
          type: 'value',
          max: _this.max,
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: ['rgba(214, 255, 255, 0.2)']
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
        }],
        series: _this.createSeries()
      })

      // 自动轮播tooltip和鼠标悬停效果
      setInterval(() => {
        // 切换到下一个数据项
        currentIndex = (currentIndex + 1) % data.length

        // 高亮当前数据项
        this.chart?.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex
        })

        // 显示当前数据项的tooltip
        this.chart?.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: currentIndex
        })

        // 延时一段时间后取消高亮和隐藏tooltip
        setTimeout(() => {
          this.chart?.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: currentIndex
          })
          this.chart?.dispatchAction({
            type: 'hideTip'
          })
        }, 2500) // 延时2秒后取消高亮和隐藏tooltip
      }, 3000) // 每3秒切换一次数据项
    }
  }
}
</script>