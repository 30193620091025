<template>
  <div ref="canvasContainer" style="width: 100%; height: 100%;"></div>
</template>
  
<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { VueThreejs } from 'vue-threejs'
// import { AnimationMixer } from 'three/examples/jsm/animation/AnimationMixer'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'


export default {
  components: {
    VueThreejs,
  },
  mounted () {
    this.init3DScene()
  },
  methods: {
    async init3DScene () {
      console.log(THREE)
      const container = this.$refs.canvasContainer

      // 控制动画是否转动
      let isAnimating = true

      // 创建场景
      const scene = new THREE.Scene()
      // scene.background = new THREE.Color(0xf0f0f0) // 设置背景色

      // 创建相机
      const camera = new THREE.PerspectiveCamera(
        12,
        container.clientWidth / container.clientHeight,
        0.1,
        1000
      )
      // 设置相机位置
      camera.position.set(0, 10, 30) // 设置相机在 (0, 2, 8) 的位置
      camera.lookAt(-0.2, 1, 0) // 设置相机的视点位置

      // 添加环境光
      // const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
      // scene.add(ambientLight)

      // 创建渲染器
      const renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setSize(container.clientWidth, container.clientHeight)
      container.appendChild(renderer.domElement)

      // 加载HDR环境光贴图
      const rgbeLoader = new RGBELoader()
      const hdrTexture = await new Promise((resolve, reject) => {
        rgbeLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/dancing_hall21_2k.hdr', resolve, undefined, reject)
      })

      const pmremGenerator = new THREE.PMREMGenerator(renderer)
      pmremGenerator.compileEquirectangularShader()

      const hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(hdrTexture)
      const hdrCubeMap = hdrCubeRenderTarget.texture

      // scene.background = hdrCubeMap // 将HDR环境贴图应用为场景背景
      scene.environment = hdrCubeMap // 将HDR环境贴图应用于场景光照

      // 创建GLTF加载器
      const gltfLoader = new GLTFLoader()
      const models = [] // 存储加载的模型
      let model1
      let model2
      let model3
      let model4
      let model5
      let model11
      let model12
      let model13
      let model14
      let model15
      let model16
      let model17
      let model18
      let model19
      let model20

      // 加载GLB模型 -- 底图
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/scene.glb', (gltf) => {
        model1 = gltf.scene
        scene.add(model1)
        models.push(model1)
      })
      // 加载GLB模型 -- 底图
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/back.glb', (gltf) => {
        model5 = gltf.scene
        scene.add(model5)
        models.push(model5)
      })
      // 风车
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/windmill.glb', (gltf) => {
        model2 = gltf.scene
        scene.add(model2)
        models.push(model2)

        // 创建动画混合器
        const mixer = new THREE.AnimationMixer(model2)

        // 获取所有动画片段
        const clips = gltf.animations

        // 将动画片段添加到混合器
        clips.forEach((clip) => {
          mixer.clipAction(clip).play()
        })

        // 渲染循环
        const animate = () => {
          requestAnimationFrame(animate)
          mixer.update(0.02) // 更新动画
          renderer.render(scene, camera)
        }

        animate()
      })
      // 光伏
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/photovoltaic.glb', (gltf) => {
        model3 = gltf.scene
        scene.add(model3)
        models.push(model3)
      })

      // 车
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/car.glb', (gltf) => {
        model4 = gltf.scene
        scene.add(model4)
        models.push(model4)

        // 创建动画混合器
        const mixer = new THREE.AnimationMixer(model4)

        // 获取所有动画片段
        const clips = gltf.animations

        // 将动画片段添加到混合器
        clips.forEach((clip) => {
          mixer.clipAction(clip).play()
        })

        // 渲染循环
        const animate = () => {
          requestAnimationFrame(animate)
          mixer.update(0.02) // 更新动画
          renderer.render(scene, camera)
        }

        animate()
      })
      // 楼栋
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/9b0ecf45-810a-497a-a4c2-4988c1b25c8e1L.glb', (gltf) => {
        model11 = gltf.scene
        scene.add(model11)
        models.push(model11)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/2L.glb', (gltf) => {
        model12 = gltf.scene
        scene.add(model12)
        models.push(model12)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/3L.glb', (gltf) => {
        model13 = gltf.scene
        scene.add(model13)
        models.push(model13)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/4L.glb', (gltf) => {
        model14 = gltf.scene
        scene.add(model14)
        models.push(model14)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/5L.glb', (gltf) => {
        model15 = gltf.scene
        scene.add(model15)
        models.push(model15)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/6L.glb', (gltf) => {
        model16 = gltf.scene
        scene.add(model16)
        models.push(model16)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/7L.glb', (gltf) => {
        model17 = gltf.scene
        scene.add(model17)
        models.push(model17)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/8L.glb', (gltf) => {
        model18 = gltf.scene
        scene.add(model18)
        models.push(model18)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/9L.glb', (gltf) => {
        model19 = gltf.scene
        scene.add(model19)
        models.push(model19)
      })
      gltfLoader.load('https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/10L.glb', (gltf) => {
        model20 = gltf.scene
        scene.add(model20)
        models.push(model20)
      })


      // 射线拾取
      const raycaster = new THREE.Raycaster()
      const mouse = new THREE.Vector2()

      window.addEventListener('click', () => {
        const rect = container.getBoundingClientRect()
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1
        raycaster.setFromCamera(mouse, camera)
        const intersects = raycaster.intersectObjects(models, true)
        if (intersects.length > 0) {
          const clickedModel = intersects[0].object
          console.log('Clicked model:', clickedModel)
          this.$emit('clickModel', clickedModel.name)
          let { name } = clickedModel
          // 
          if (name.indexOf('风车') !== -1 || name.indexOf('太阳能') !== -1 || name.indexOf('楼') !== -1) {
            isAnimating = false
          } else {
            isAnimating = true
          }

        }
      })

      // 创建控制器对象
      // const controls = new OrbitControls(camera, renderer.domElement)

      // 渲染循环
      const animate = () => {
        requestAnimationFrame(animate)

        // 更新控制器
        // controls.update()

        // 检查模型是否已加载
        if (isAnimating && model1 && model2 && model3 && model5 && model11 && model12 && model13 && model14 &&
          model15 && model16 && model17 && model18 && model19 && model20) {
          // 让模型自转
          model1.rotation.y += 0.001 // 自转速度
          model2.rotation.y += 0.001 // 自转速度
          model3.rotation.y += 0.001 // 自转速度
          model4.rotation.y += 0.001 // 自转速度
          model5.rotation.y += 0.001 // 自转速度
          model11.rotation.y += 0.001 // 自转速度
          model12.rotation.y += 0.001 // 自转速度
          model13.rotation.y += 0.001 // 自转速度
          model14.rotation.y += 0.001 // 自转速度
          model15.rotation.y += 0.001 // 自转速度
          model16.rotation.y += 0.001 // 自转速度
          model17.rotation.y += 0.001 // 自转速度
          model18.rotation.y += 0.001 // 自转速度
          model19.rotation.y += 0.001 // 自转速度
          model20.rotation.y += 0.001 // 自转速度
        }

        renderer.render(scene, camera)
      }

      animate()
    },
    onMouseClick (event) {
      console.log(event)

      return
    },
  },
}
</script>
  
<style>
/* Add any necessary styling for the component */
</style>
  