<template>
  <div ref="page4" class="page4">
    <video ref="videoPlayer" name="media" :autoplay="autoplay" controls loop>
      <source src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/animation.mp4"
        type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  data () {
    return {
      autoplay: false,
    }
  },
  mounted () {

  },
  methods: {
    startVideo () {
      // 当用户点击按钮后，触发视频播放
      this.$nextTick(() => {
        this.autoplay = true
        this.$refs.videoPlayer.play()
      })
    },
    pauseVideo () {
      // 当用户点击按钮后，触发视频播放
      this.$nextTick(() => {
        this.autoplay = false
        this.$refs.videoPlayer.pause()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  height: 100%;
}
</style>