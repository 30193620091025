<template>
  <div id="app">
    <div class="main-box">
      <SacleBox>
        <div class="main-wraper">
          <router-view />
        </div>
      </SacleBox>
    </div>
  </div>
</template>

<script>

import SacleBox from '@/components/SacleBox'
export default {
  components: {
    SacleBox
  },
}
</script>
<style lang="scss">
*,
p {
  margin: 0;
  padding: 0;
}

.main-box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-size: 100% 100%;
  background: #034447;
  color: #fff;

  .main-wraper {
    height: 1080px;
    width: 1920px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
