<template>
  <div class="HomeHeader">
    <div class="carbon-data-header">
      <div>
        <div v-if="isShowLeft" class="left">
          <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/equipment.png"
            alt="">
          在线设备数量： <span>{{ totalUser }}</span> 个
        </div>
      </div>
      <h2>{{ name }}</h2>
      <div class="date">
        <p>{{ currentDateTime.formattedDateTime }}</p>
        <p>{{ currentDateTime.formattedDayOfWeek }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { numAnimation } from '@/utils'
export default {
  props: {
    name: {
      type: String,
      default: '重庆市碳数据全景大屏'
    },
    // 是否显示左边内容
    isShowLeft: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      totalUser: 0,
      currentDateTime: {}
    }
  },
  mounted () {
    this.updateCurrentDateTime()
    this.updateEquipment()
  },
  methods: {
    // 时间
    updateCurrentDateTime () {
      this.currentDateTime = JSON.parse(JSON.stringify(this.$utils.getCurrentDateTime()))
      setTimeout(this.updateCurrentDateTime, 1000)
    },
    // 设置设备数量动画
    updateEquipment () {
      numAnimation(this.totalUser, this.getRandomNumber(2500, 3000) || 0, this, 'totalUser')
      setTimeout(this.updateEquipment, 4000)
    },
    // 获取随机数
    getRandomNumber (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>

<style lang="scss" scoped>
.HomeHeader {
  background: linear-gradient(180deg, rgba(20, 242, 227, 0.3) 0%, rgba(2, 72, 72, 0) 100%);
}

.carbon-data-header {
  width: 100%;
  height: 146px;
  background: url('https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/carbon-data-header-back.png');
  background-size: contain;
  display: flex;
  justify-content: space-between;
  padding-top: 19px;
  box-sizing: border-box;

  >div {
    flex: 1;
    text-align: right;

    p {
      font-size: 16px;
      font-family: DIN-Medium, DIN;
      color: #09E8F8;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }

  .left {
    text-align: left;
    padding-left: 24px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #D6FFFF;
    display: flex;

    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    span {
      color: #12EFFF;
      font-weight: bold;
    }
  }

  h2 {
    flex: 1;
    width: 500px;
    text-align: center;
    font-size: 36px;
    // font-family: YouSheBiaoTiHei;
    color: #12EFFF;
    // text-shadow: 0px 0px 10px rgba(83, 238, 255, 0.4);
  }

  .date {
    padding-right: 25px;
  }
}
</style>